<template>
  <b-card
    v-if="tableData"
    no-body
    class="ml-2"
  >
    <div class="d-flex">
      <h4 class="mb-2 mr-1">
        {{ $t('KPI') }}
      </h4>
      <feather-icon
        v-if="canEditCompetition"
        icon="EditIcon"
        size="16"
        class="text-muted cursor-pointer"
        @click="$router.push({ name: 'kpi-list-id', params: { id: encryptId }})"
      />
    </div>
    <b-table
      :items="tableData"
      responsive
      :fields="kpiTableColumns"
      :empty-text="$t('NO_MATCHING_RECORDS_FOUND')"
      primary-key="id"
      class="mb-0"
    >

      <!-- Name -->
      <template #cell(name)="data">
        <div class="d-flex align-items-center">
          <span class="font-weight-bolder mr-1">{{ data.item.name }}</span>
        </div>
      </template>

      <!-- Unit -->
      <template #cell(Unit)="data">
        <div class="d-flex align-items-center">
          <div>
            <div class="font-weight-bolder">
              <span>{{ data.item.unit }}</span>
            </div>
          </div>
        </div>
      </template>
      <!-- ValueType -->
      <template #cell(ValueType)="data">
        <div class="d-flex align-items-center">
          <div>
            <div class="font-weight-bolder">
              <span>{{ data.item.value_type }}</span>
            </div>
          </div>
        </div>
      </template>
      <!-- Description -->
      <template #cell(Description)="data">
        <div class="d-flex align-items-center">
          <p v-html="strSubstring(data.item.str1,15)" />
        </div>
      </template>
      <!-- Short Description -->
      <template #cell(ShortDescription)="data">
        <div class="d-flex align-items-center">
          <span>{{ data.item.str2 }}</span>
        </div>
      </template>

    </b-table>
  </b-card>
</template>

<script>
import { BCard, BTable } from 'bootstrap-vue'
import { mixinList } from '@/constants/mixinValidations'

export default {
  components: {
    BCard,
    BTable,
  },
  mixins: [mixinList],
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    canEditCompetition: {
      type: Boolean,
      default: () => true,
    },
    encryptId: {
      type: String,
      default: () => 0,
      required: true,
    },
  },
  setup() {
    // Table Handlers
    const kpiTableColumns = [
      { key: 'Name', sortable: false },
      { key: 'Unit', sortable: false },
      { key: 'ValueType', sortable: false },
      { key: 'Description', sortable: false },
      { key: 'ShortDescription', sortable: false },
    ]

    return {
      kpiTableColumns,
    }
  },

}

</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
