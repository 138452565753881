<template>
  <b-card
    v-if="tableData"
    no-body
    class="ml-2"
  >
    <div class="d-flex">
      <h4 class="mb-2 mr-1">
        {{ $t('Goals') }}
      </h4>
      <feather-icon
        v-if="canEditCompetition"
        icon="EditIcon"
        size="16"
        class="text-muted cursor-pointer"
        @click="$router.push({ name: 'goals-list-id', params: { id: encryptId }})"
      />
    </div>
    <b-table
      :items="tableData"
      responsive
      :fields="goalsTableColumns"
      :empty-text="$t('NO_MATCHING_RECORDS_FOUND')"
      primary-key="id"
      class="mb-0"
    >

      <!-- Description -->
      <template #cell(Description)="data">
        <div class="d-flex align-items-center">
          <span class="font-weight-bolder mr-1">{{ data.item.description }}</span>
        </div>
      </template>

      <!-- Unit -->
      <template #cell(Source)="data">
        <div class="d-flex align-items-center">
          <div>
            <div class="font-weight-bolder">
              <span>{{ data.item.src }}</span>
            </div>
          </div>
        </div>
      </template>
      <!-- Float value -->
      <template #cell(Value)="data">
        <div class="d-flex align-items-center">
          <div>
            <div class="font-weight-bolder">
              <span>{{ data.item.floatvalue }}</span>
            </div>
          </div>
        </div>
      </template>
      <!-- GoalType -->
      <template #cell(GoalType)="data">
        <div class="d-flex align-items-center">
          <span>{{ data.item.goal_type }}</span>
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { BCard, BTable } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    canEditCompetition: {
      type: Boolean,
      default: () => true,
    },
    encryptId: {
      type: String,
      default: () => 0,
      required: true,
    },
  },
  setup() {
    // Table Handlers
    const goalsTableColumns = [
      { key: 'Description', sortable: false },
      { key: 'Source', sortable: false },
      { key: 'Value', sortable: false },
      { key: 'GoalType', sortable: false },
    ]

    return {
      goalsTableColumns,
    }
  },

}

</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
